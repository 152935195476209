import serialize from 'form-serialize';
import validator from 'validator';
import observe from './observe';

const SELECTOR_FORM = '.js-contact-form';
const SELECTOR_MENU_TOGGLE = '.js-menu-toggle';
const SELECTOR_SECTION = '.js-section';
const OFFSET = 100;

const onScroll = () => {
  document.body.classList[window.pageYOffset > OFFSET ? 'add' : 'remove']('is-scrolled');
};

const onToggleClick = () => {
  document.body.classList.toggle('is-menu-visible');
};

const showErrorMsg = (name) => {
  const input = document.querySelector(`[name=${name}]`);
  const errorMsg = input.closest('.field');
  errorMsg.classList.add('is-invalid');
};

const onFormSubmit = (e) => {
  e.preventDefault();
  document
    .querySelectorAll('.is-invalid')
    .forEach(el => el.classList.remove('is-invalid'));

  let isValid = true;

  const { name, tel, mail, consent } = serialize(e.target, { hash: true, empty: true });

  if (validator.isEmpty(name)) {
    isValid = false;
    showErrorMsg('name');
  }
  if (!validator.isNumeric(tel) || validator.isEmpty(tel)) {
    isValid = false;
    showErrorMsg('tel');
  }
  if (!validator.isEmail(mail) || validator.isEmpty(mail)) {
    isValid = false;
    showErrorMsg('mail');
  }
  if (consent !== 'on') {
    isValid = false;
    showErrorMsg('consent');
  }

  if (isValid) {
    // send
  }

};

const onLinkClick = trigger => {
  trigger.onclick = function(e) {
    e.preventDefault();
    let hash = this.getAttribute('href');
    let target = document.querySelector(hash);
    let offsetPosition = target.offsetTop;

    const isSmall = window.matchMedia('(max-width: 480px)');
    if (isSmall.matches) {
      document.body.classList.remove('is-menu-visible');
    }

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  };
};

const addVisibilityClass = ({ isIntersecting, target }) => {
  if (isIntersecting) {
    target.classList.add('is-in-viewport');
  } else {
    target.classList.remove('is-in-viewport');
  }
};

const init = () => {
  window.addEventListener('scroll', onScroll);
  document.querySelector(SELECTOR_MENU_TOGGLE).addEventListener('click', onToggleClick);
  document.querySelector(SELECTOR_FORM).addEventListener('submit', onFormSubmit);
  document.querySelectorAll('a[href*="#"]').forEach(onLinkClick);
  [].forEach.call(
    document.querySelectorAll(SELECTOR_SECTION),
    item => observe(item, addVisibilityClass)
  );
};


export default init;
